// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s8AipVtTtv8zzSK2pOYS {
  display: grid;
  grid-template-areas:
    "time title location"
    "time speaker speaker"
    "progress progress progress";
  grid-template-columns: min-content auto min-content;
  column-gap: 1em;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.596);
  border-radius: 0.2em;
  padding: 0.5em;
}

.AzlRpBY6l7lOcH1mX9jJ {
  background: rgb(10, 64, 139);
  background: linear-gradient(
    184deg,
    rgba(10, 64, 139, 1) 0%,
    rgba(69, 20, 153, 1) 100%
  );
}

.OvBtFpabvFtQ6mD7PEsl {
  grid-area: time;
  font-size: 3em;
  margin: 0;
}

.VDguotLK0MclG3OXJ9Yy {
  grid-area: title;
  font-size: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  margin: 0;
}

.FKhJzyXCLk6AWzLWC_8l {
  grid-area: speaker;
  font-size: 1em;
  margin: 0;
}

.yIly6pggZvwPRR6imDwL {
  grid-area: location;
  font-size: 2em;
  margin: 0;
  white-space: nowrap;
}

.AzlRpBY6l7lOcH1mX9jJ .aJ9uH_zQIpNaYAD6bVpI {
  margin-top: 0.2em;
  background-color: white;
  width: 0%;
  height: 0.2em;
  grid-area: progress;
  border-radius: 0.1em;
}
`, "",{"version":3,"sources":["webpack://./src/EventCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;;gCAG8B;EAC9B,mDAAmD;EACnD,eAAe;EACf,YAAY;EACZ,sCAAsC;EACtC,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B;;;;GAIC;AACH;;AAEA;EACE,eAAe;EACf,cAAc;EACd,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".eventcard {\n  display: grid;\n  grid-template-areas:\n    \"time title location\"\n    \"time speaker speaker\"\n    \"progress progress progress\";\n  grid-template-columns: min-content auto min-content;\n  column-gap: 1em;\n  margin: 10px;\n  background-color: rgba(0, 0, 0, 0.596);\n  border-radius: 0.2em;\n  padding: 0.5em;\n}\n\n.active {\n  background: rgb(10, 64, 139);\n  background: linear-gradient(\n    184deg,\n    rgba(10, 64, 139, 1) 0%,\n    rgba(69, 20, 153, 1) 100%\n  );\n}\n\n.eventtime {\n  grid-area: time;\n  font-size: 3em;\n  margin: 0;\n}\n\n.eventtitle {\n  grid-area: title;\n  font-size: 2em;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n  white-space: nowrap;\n  margin: 0;\n}\n\n.eventspeaker {\n  grid-area: speaker;\n  font-size: 1em;\n  margin: 0;\n}\n\n.eventlocation {\n  grid-area: location;\n  font-size: 2em;\n  margin: 0;\n  white-space: nowrap;\n}\n\n.active .progressbar {\n  margin-top: 0.2em;\n  background-color: white;\n  width: 0%;\n  height: 0.2em;\n  grid-area: progress;\n  border-radius: 0.1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventcard": `s8AipVtTtv8zzSK2pOYS`,
	"active": `AzlRpBY6l7lOcH1mX9jJ`,
	"eventtime": `OvBtFpabvFtQ6mD7PEsl`,
	"eventtitle": `VDguotLK0MclG3OXJ9Yy`,
	"eventspeaker": `FKhJzyXCLk6AWzLWC_8l`,
	"eventlocation": `yIly6pggZvwPRR6imDwL`,
	"progressbar": `aJ9uH_zQIpNaYAD6bVpI`
};
export default ___CSS_LOADER_EXPORT___;
