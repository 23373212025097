import React, { useEffect, useState } from "react";
import EventCard from "./EventCard";
import styles from "./App.css";
import { Event, eventsAfter } from "./Events";
import Clock from "./Clock";
import { useTime } from "./Time";

export default function App(): JSX.Element {
  const time = useTime();
  const [leftEvents, setLeftEvents] = useState([] as Event[]);

  useEffect(() => {
    eventsAfter(time).then((events) => {
      setLeftEvents(events);
    });
  }, [time]);

  const eventCards = leftEvents.map((event) => (
    <EventCard
      {...event}
      key={JSON.stringify(event)}
      currentTime={time.toString()}
    />
  ));

  return (
    <>
      <div className={styles.app}>
        <img className={styles.logo} src="/logo.png" />
        <div className={styles.title}>CodeNight 2024</div>
        <div className={styles.clock}>
          <Clock />
        </div>
        <div className={styles.content}>{eventCards}</div>
      </div>
    </>
  );
}
