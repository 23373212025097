export type Event = {
  title: string;
  speaker?: string;
  startTime: string;
  location: string;
  duration: number;
};

let cachedEvents: Event[] | null = null;

export default async function eventList(): Promise<Event[]> {
  try {
    const response = await fetch("/events.json");
    return (cachedEvents = await response.json());
  } catch {
    return (
      cachedEvents ?? [
        {
          title: "Events konnten nicht abgerufen werden.",
          startTime: new Date().toISOString(),
          location: "",
          speaker: "Versuche es in einer Minute erneut",
          duration: 1,
        },
      ]
    );
  }
}

export function eventsAfter(time: Date): Promise<Event[]> {
  return eventList().then((events) =>
    events
      .sort(
        (e1, e2) =>
          new Date(e1.startTime).getTime() - new Date(e2.startTime).getTime(),
      )
      .filter((event) => {
        const date = new Date(event.startTime);
        const endTime = new Date(date.getTime() + event.duration * 60000);
        return endTime >= time;
      }),
  );
}
