import React from "react";

export function useTime(): Date {
  const [now, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return now;
}
