import React from "react";
import styles from "./EventCard.css";
import { Event } from "./Events";

export type Time = {
  currentTime: string;
};

export default function EventCard(props: Event & Time): JSX.Element {
  const startTime = new Date(props.startTime);
  const endTime = new Date(startTime.getTime() + props.duration * 60000);
  const dateFormatted = startTime.toLocaleTimeString("DE-de", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentTime = new Date(props.currentTime);

  const isActive = startTime <= currentTime && endTime >= currentTime;
  const passedSeconds = (currentTime.getTime() - startTime.getTime()) / 1000;
  const leftMinutes = Math.floor(
    (endTime.getTime() - currentTime.getTime()) / 60000,
  );
  const leftTimeString = isActive
    ? "noch " + formatTime(leftMinutes)
    : formatTime(props.duration);
  const progressPercentage =
    (passedSeconds / (props.duration * 60)) * 100 + "%";

  const secondLine = [props.speaker, leftTimeString]
    .filter((e) => e !== undefined)
    .join(" · ");
  return (
    <>
      <div className={styles.eventcard + " " + (isActive ? styles.active : "")}>
        <span className={styles.eventtime}>{dateFormatted}</span>
        <span className={styles.eventtitle}>{props.title}</span>
        <span className={styles.eventspeaker}>{secondLine}</span>
        <span className={styles.eventlocation}>{props.location}</span>
        <div
          className={styles.progressbar}
          style={{ width: progressPercentage }}
        ></div>
      </div>
    </>
  );
}

function formatTime(fullMinutes: number): string {
  const days = Math.floor(fullMinutes / (24 * 60));
  const hours = Math.floor((fullMinutes % (24 * 60)) / 60);
  const minutes = fullMinutes % 60;

  const partialStrings = [];
  if (days) partialStrings.push(days + " Tag" + (days === 1 ? "" : "e"));
  if (hours) partialStrings.push(hours + " Stunde" + (hours === 1 ? "" : "n"));
  if (minutes)
    partialStrings.push(minutes + " Minute" + (minutes === 1 ? "" : "n"));
  return partialStrings.join(" ");
}
