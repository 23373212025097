// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OzLVNjax6xuxtsGVEu9w {
  display: grid;
  grid-template-areas:
    "eventtitle wifi clock logo"
    "content content content content";
  grid-template-columns: auto min-content min-content min-content;
  grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  height: 100%;
}

.W0n17Q0mtgfKC8Lmj6vT {
  grid-area: logo;
  align-self: center;
  height: 4em;
  margin: 1em;
  border-radius: 0.5em;
}

.K_2Sneo5zTwoin9framQ {
  grid-area: wifi;
}

.V8B7rqnYmo_Ur5uY5B7S {
  align-self: center;
  grid-area: eventtitle;
  font-size: 4em;
  padding-left: 1rem;
}

.eGTCbAlWe0nD6aK8ttgp {
  grid-area: content;
}

.PDkb1XSAHv4iR7oH3Vhb {
  align-self: center;
  grid-area: clock;
  font-size: 3em;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;;qCAEmC;EACnC,+DAA+D;EAC/D,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".app {\n  display: grid;\n  grid-template-areas:\n    \"eventtitle wifi clock logo\"\n    \"content content content content\";\n  grid-template-columns: auto min-content min-content min-content;\n  grid-template-rows: max-content auto;\n  width: 100%;\n  height: 100%;\n  height: 100%;\n}\n\n.logo {\n  grid-area: logo;\n  align-self: center;\n  height: 4em;\n  margin: 1em;\n  border-radius: 0.5em;\n}\n\n.wifi {\n  grid-area: wifi;\n}\n\n.title {\n  align-self: center;\n  grid-area: eventtitle;\n  font-size: 4em;\n  padding-left: 1rem;\n}\n\n.content {\n  grid-area: content;\n}\n\n.clock {\n  align-self: center;\n  grid-area: clock;\n  font-size: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `OzLVNjax6xuxtsGVEu9w`,
	"logo": `W0n17Q0mtgfKC8Lmj6vT`,
	"wifi": `K_2Sneo5zTwoin9framQ`,
	"title": `V8B7rqnYmo_Ur5uY5B7S`,
	"content": `eGTCbAlWe0nD6aK8ttgp`,
	"clock": `PDkb1XSAHv4iR7oH3Vhb`
};
export default ___CSS_LOADER_EXPORT___;
