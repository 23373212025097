// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "NeoSans";
  src: url("/NeoSans.ttf");
}

html,
body,
#Vp5B0KOqU5p4JBGYpgRj {
  width: 100%;
  height: 100%;
  min-height: 100%;

  margin: 0;
  padding: 0;
  background: linear-gradient(
    122deg,
    rgba(4, 31, 41, 1) 0%,
    rgba(4, 31, 41, 1) 32%,
    rgba(9, 72, 96, 1) 100%
  );
  color: white;
}

body {
  padding: 16px;
  box-sizing: border-box;
}

:root {
  font-size: 18pt;
  font-family: "NeoSans";
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 10pt;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;;;EAGE,WAAW;EACX,YAAY;EACZ,gBAAgB;;EAEhB,SAAS;EACT,UAAU;EACV;;;;;GAKC;EACD,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC","sourcesContent":["@font-face {\n  font-family: \"NeoSans\";\n  src: url(\"/NeoSans.ttf\");\n}\n\nhtml,\nbody,\n#root {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n\n  margin: 0;\n  padding: 0;\n  background: linear-gradient(\n    122deg,\n    rgba(4, 31, 41, 1) 0%,\n    rgba(4, 31, 41, 1) 32%,\n    rgba(9, 72, 96, 1) 100%\n  );\n  color: white;\n}\n\nbody {\n  padding: 16px;\n  box-sizing: border-box;\n}\n\n:root {\n  font-size: 18pt;\n  font-family: \"NeoSans\";\n}\n\n@media only screen and (max-width: 600px) {\n  :root {\n    font-size: 10pt;\n  }\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\nbody::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\nbody {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Vp5B0KOqU5p4JBGYpgRj`
};
export default ___CSS_LOADER_EXPORT___;
