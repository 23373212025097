import React from "react";

export default function Clock(): JSX.Element {
  const time = new Date();
  const timeFormatted = time.toLocaleTimeString("DE-de", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return <span>{timeFormatted}</span>;
}
