import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import styles from "./style.css";

const rootDiv = document.createElement("div");
rootDiv.id = styles.root;
document.body.appendChild(rootDiv);

const reactRoot = createRoot(rootDiv);
reactRoot.render(<App />);
